import { defineStore } from 'pinia'
import { api } from '@/plugins/api'

export const useCategoriesStore = defineStore('categories', {
  state: () => ({
    // Categories
    suggestedCategories: [],
    allCategories: [],
    currentCategory: null as any,
    loadingCategories: false as boolean
  }),
  actions: {
    async fetchCategories(placeTags?: Array<string>) {
      if (placeTags?.length) {
        const response = await api.category.getAllCategoriesWithTags(placeTags)
        this.allCategories = response.data
      } else {
        const response = await api.category.getAll()
        this.allCategories = response.data
      }

      if (this.allCategories.length >= 12) {
        this.suggestedCategories = this.allCategories.slice(0, 12)
      } else {
        this.suggestedCategories = this.allCategories
      }
    }
  }
})
