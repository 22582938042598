import { defineStore } from 'pinia'
import { api } from '@/plugins/api'
import type { ServiceTemplate } from '@/interfaces/serviceTemplate.interface'
import { useCategoriesStore } from '@/stores/categories'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import { useGlobalStore } from '@/stores/global'

export const useServiceTemplatesStore = defineStore('serviceTemplates', {
  state: () => ({
    serviceTemplates: [] as Array<ServiceTemplate>,
    suggestedServiceTemplates: [] as Array<ServiceTemplate>,
    filteredServiceTemplates: [] as Array<ServiceTemplate>,
    currentVisitedServiceTemplate: {} as ServiceTemplate,
    currentServiceTemplate: null as ServiceTemplate | null,
    isShowingMore: false
  }),
  getters: {
    currentServiceTemplates(): Array<ServiceTemplate> {
      if (useCategoriesStore().currentCategory === null)
        return this.sortByTitle(this.serviceTemplates)
      else
        return this.sortByTitle(
          filter(this.serviceTemplates, (item: ServiceTemplate) =>
            includes(item.categories, useCategoriesStore().currentCategory?.id)
          )
        )
    }
  },
  actions: {
    sortByTitle(list): Array<ServiceTemplate> {
      const key = `title${useGlobalStore().upperLocale}`
      return list.sort((a, b) => {
        if (a.weight !== null && b.weight !== null) {
          return b.weight - a.weight // Sort by weight descending
        } else if (a.weight !== null && b.weight === null) {
          return -1 // a comes before b
        } else if (a.weight === null && b.weight !== null) {
          return 1 // b comes before a
        } else if (a.weight === null && b.weight === null) {
          return a[key].localeCompare(b[key]) // Sort alphabetically by nameFr or nameEn
        }
        return 0
      })
    },
    async fetchServiceTemplatesSEO() {
      if (this.serviceTemplates.length === 0) {
        const response = await api.serviceTemplates.fetchServiceTemplates()
        if (response && response.data) {
          this.serviceTemplates = response.data
          this.suggestedServiceTemplates = response.data.slice(0, 10)
        }
      }
    },
    async fetchServiceTemplateWithSlug(slug: string) {
      const response = await api.serviceTemplates.fetchWithSlug(slug)
      this.currentVisitedServiceTemplate = response.data
    },
    serviceTemplatesFindBy(by: string, value: any) {
      return this.serviceTemplates.find((item) => item[by] === value)
    }
  }
})
