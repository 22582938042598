import trim from 'lodash/trim'
import startsWith from 'lodash/startsWith'
import split from 'lodash/split'
import some from 'lodash/some'
import replace from 'lodash/replace'
import join from 'lodash/join'
import includes from 'lodash/includes'
import get from 'lodash/get'
import filter from 'lodash/filter'
import { inferCookieDomain } from '@/helpers/env'
import { TrimozError } from '@/trimoz-vue-error-handling/trimoz-error'

/**
 * Returns whether the provided element is currently rendered in the viewport
 * @param element
 * @returns {boolean}
 */
export function isInViewport(element) {
  if (element instanceof HTMLElement) {
    const rect = element.getBoundingClientRect()
    /**
     * On some Android devices, bounding box is too precise.
     * Therefore, we round to floor to ignored part of pixel which anyway doesn't make sense in our context.
     */
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      Math.floor(rect.bottom) <= (window.innerHeight || document.documentElement.clientHeight) &&
      Math.floor(rect.right) <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }
  return false
}

export function formatServiceDescriptionText(description, pattern, value) {
  if (includes(description, pattern)) {
    return replace(description, pattern, value)
  }
  return description
}

export const convertApiErrorIntoTrimozError = (error) => {
  const contextCode = get(error, 'response.data.messages.errors[0].context.error_code', null)
  const internalCode = retrieveErrorInternalCode(error)

  return new TrimozError(error, 'errors.generic', internalCode, contextCode)
}

export const retrieveErrorInternalCode = (error) => {
  return get(error, 'response.data.messages.errors[0].internal_code', null)
}

export const expectedRtkpValue = (domain = window.location.hostname) => {
  return join(['profile', inferCookieDomain(domain)], '.')
}

export const hasRtkpCookie = (domain = window.location.hostname, cookies = document.cookie) => {
  const rtkps = filter(split(cookies, '; '), (cookie) => startsWith(cookie, 'rtkp='))
  return some(rtkps, (rtkp) => {
    const rtkpValue = split(rtkp, '=')[1]
    return rtkpValue === expectedRtkpValue(domain)
  })
}

// Cookies
export const getCookie = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)

  return parts.length !== 2 ? undefined : parts.pop().split(';').shift()
}

export const setCookie = (name, value, expiryDays, domain, path) => {
  const exdate = new Date()
  exdate.setDate(exdate.getDate() + (expiryDays || 365))

  const cookie = [`${name}=${value}`, `expires=${exdate.toUTCString()}`, `path=${path || '/'}`]

  if (domain) {
    cookie.push(`domain=${domain}`)
  }

  document.cookie = cookie.join(';')
}

export function removePunctuation(stringToFormat) {
  const regex = /[.,/#!$%^&*;:{}=\-_`~()]/g
  return trim(replace(replace(stringToFormat, regex, ' '), /\s+/g, ' '))
}

export function removeSpecialCharacter(stringToFormat) {
  const regex = /[^a-zA-Z0-9 ]/g
  return trim(replace(replace(stringToFormat, regex, ' '), /\s+/g, ' '))
}

export function removeHtml(stringToFormat) {
  const regex = /<[^>]*>/gm
  return trim(replace(replace(stringToFormat, regex, ' '), /\s+/g, ' '))
}

export const removeAccents = (str) => {
  return replace(str.normalize('NFD'), /[\u0300-\u036f]/g, '')
}

export const getFullPathFromPathAndQueryParams = (path, queryParams) => {
  const queryParamsString = new URLSearchParams(queryParams).toString()
  return queryParams ? `${path}?${queryParamsString}` : path
}
