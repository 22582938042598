import { defineStore } from 'pinia'
import { api } from '@/plugins/api'
import { get } from 'lodash'

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    globalSettings: {} as any
  }),
  actions: {
    async fetchGlobalSettings() {
      const response = await api.settings.getGlobalSettings()
      this.globalSettings = response.data
    },
    getById(globalSettingsId) {
      return get(useSettingsStore().globalSettings, `${globalSettingsId}.value`)
    }
  }
})
